
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import {
  IWarehouse,
  IWarehouseCreateRequest,
  IWarehouseRequest,
  IWarehouseUpdateRequest
} from "@/types/warehouse";
import { WarehouseRequest } from "@/models/warehouse";

@Component({})
export default class extends Vue {
  //warehouse
  @Action("warehouse/adminGet")
  public getWarehouse!: (warehouse_id: number) => void;

  @Action("warehouse/adminCreate")
  public create!: (params: IWarehouseCreateRequest) => boolean;

  @Action("warehouse/adminUpdate")
  public update!: (data: {
    warehouse_id: number;
    params: IWarehouseUpdateRequest;
  }) => boolean;

  @Action("warehouse/adminDelete")
  public deleteWarehouse!: (warehouse_id: number) => boolean;

  @Action("warehouse/adminIsUniqueName")
  public isUniqueName!: (params: IWarehouseRequest) => boolean;

  @Getter("warehouse/single")
  public warehouse!: IWarehouse;

  @Mutation("warehouse/clear")
  public clear!: () => void;

  //パラメータ定義
  public params: IWarehouseUpdateRequest = new WarehouseRequest();

  //変数定義
  public warehouse_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public name_errors: string[] = [];

  //ルール設定
  public rules = {
    name: [(v: string) => !!v || "倉庫名は必須です"],
    postalcode: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)",
      (v: string) => !!v || "郵便番号は必須です"
    ],
    address: [(v: string) => !!v || "住所は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.warehouse_id = Number(this.$route.params.warehouse_id); // 編集時存在
    this.clear();

    // 編集時
    if (this.warehouse_id) {
      await this.getWarehouse(this.warehouse_id);

      this.setDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.name = this.warehouse.name || "";
    this.params.postalcode = this.warehouse.postalcode || "";
    this.params.address = this.warehouse.address || "";
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.warehouse_id) {
      result = await this.update({
        warehouse_id: this.warehouse_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteWarehouse(this.warehouse_id)) {
      this.$router.go(-1);
    }
  }

  // 倉庫名の二重登録チェック
  public async name_validate() {
    // 登録時
    if (!(await this.isUniqueName(this.params)) && !this.warehouse.name) {
      return (this.name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueName(this.params)) &&
      this.warehouse.name != this.params.name
    ) {
      return (this.name_errors = ["既に使用されています"]);
    }

    return (this.name_errors = []);
  }
}
